<template>
    <v-col md="9" sm="12">
        <video id="player" playsinline controls :data-poster="video.thumbnail_url">
            <source :src="video.url" type="video/mp4"/>
        </video>
    </v-col>
</template>

<script>
import Video from "@/models/Video";
import Plyr from "plyr";

export default {
    name: "Show",
    data: function () {
        return {
            video: {},
            player: {},
        }
    },
    async mounted() {
        const videoId = this.$route.params.id
        this.video = await Video.find(videoId)

        new Plyr('#player');
    }
}
</script>

<style scoped>

</style>
